<template>
  <div class="layout">
    <el-row :gutter="15">
      <el-col :span="6"
        ><div class="grid-content bg-purple" @click="toRegister">
          <img src="../assets/a.jpg" alt="Image a" class="image-in-grid" />
          <h1><a>志愿者注册</a></h1>
        </div></el-col
      >

      <el-col :span="6"
        ><div class="grid-content bg-purple" @click="toRegister">
          <img src="../assets/b.jpg" alt="Image b" class="image-in-grid" />
          <h1><a>志愿队伍注册</a></h1>
        </div></el-col
      >

      <el-col :span="6"
        ><div class="grid-content bg-purple">
          <img src="../assets/c.png" alt="Image c" class="image-in-grid" />
          <h1><a>志愿者证书查询</a></h1>
        </div>
      </el-col>
      <el-col :span="6"
        ><div class="grid-content bg-purple" @click="to">
          <img src="../assets/d.png" alt="Image d" class="image-in-grid" />
          <h1><a>管理者登录</a></h1>
        </div></el-col
      >
    </el-row>
  </div>
</template>
<script>
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
export default {
  name: "Layout",
  components: { ElementUI },
  methods: {
    to() {
      // 使用window.location.href来跳转到另一个项目的登录页面
      window.location.href = "https://hlj-admin.xiaolietou.top/login";
    },
    toRegister() {
      this.$router.push("/register");
    },
  },
};
</script>
<style scoped>
.layout {
  width: 1200px;
  margin: auto;
  margin-top: 50px;
  display: flex;
  justify-content: center;
}
.image-in-grid {
  width: 50px;
  height: 50px;
}

.bg-purple {
  background: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
}
h1 {
  padding-left: 30px;
}
.grid-content {
  width: 270px;
  border-radius: 4px;
  height: 120px;
  cursor: pointer;
}
</style>
