<template>
  <div class="container1">
    <router-view />
  </div>
</template>

<script>
import NewsPage from "@/components/NewsPage.vue";
import SwiperPage from "@/components/SwiperPage.vue";
import TopNavbar from "@/components/TopNavbar.vue";
import Layout from "@/components/Layout.vue";
import TabPage from "@/components/TabPage.vue";
import LinkedPage from "@/components/LinkedPage.vue";
import SubmenuPage from "@/components/SubmenuPage.vue";
import QRCode from "@/components/QRCode.vue";
export default {
  name: "App",
  components: { TopNavbar,Layout ,SwiperPage, NewsPage,TabPage,LinkedPage,SubmenuPage,QRCode},
  data() {
    return {};
  },
  methods: {
    checkLogin() {
      let tokenValue = localStorage.getItem("token-value");
      let userInfoValue = localStorage.getItem("user-info");
      if (tokenValue && userInfoValue) {
        this.$store.commit("login", JSON.parse(userInfoValue));
        console.log("login success");
      }
    },
  },
  created() {
    this.checkLogin();
  },
};
</script>

<style>
.logo {
  cursor: pointer;
  height: 70px;
}
</style>
