<template>
  <div class="tabs-container">
    <h2>动态</h2>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="全部" name="first"></el-tab-pane>
      <el-tab-pane label="福建" name="second"></el-tab-pane>
      <el-tab-pane label="广西" name="third"></el-tab-pane>
      <el-tab-pane label="广东" name="fourth"></el-tab-pane>
      <el-tab-pane label="土耳其" name="five"></el-tab-pane>
      <el-tab-pane label="印尼" name="six"></el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
export default {
  name: "TabPage",
  data() {
    return {
      activeName: "second",
    };
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
  },
};
</script>
<style scoped>
.tabs-container {
  width: 1200px;
  margin: auto;
  margin-top: 20px;
}
</style>
