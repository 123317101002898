<template>
  <div class="container">
    <div class="container-img">
      <el-row :gutter="20">
        <el-col :span="8"
          ><div class="grid-content bg-purple">
            <img
              src="../assets/m.png"
              alt="Image a"
              class="image-in-grid"
            /></div
        ></el-col>

        <el-col :span="8"
          ><div class="grid-content bg-purple">
            <img
              src="../assets/n.jpg"
              alt="Image b"
              class="image-in-grid"
            /></div
        ></el-col>

        <el-col :span="8"
          ><div class="grid-content bg-purple">
            <img src="../assets/v.jpg" alt="Image c" class="image-in-grid" />
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="container2">
      <el-row :gutter="20">
        <el-col :span="6" v-for="item in activityList" :key="item.id">
          <el-card class="card-background">
            <img class="img-1" :src="item.activityPictureUrl" />
            <div style="padding: 14px; color: balck">
              <h3>{{ item.title }}</h3>
              <p>{{ item.activityContent.slice(0, 10) }}...</p>
              <p>服务时长: {{ item.activityTime }} 小时</p>
              <p>活动创建时间: {{ item.createTime | dateFormat }}</p>
              <p>活动更新时间: {{ item.updateTime | dateFormat }}</p>
              <el-button class="bottom-button" @click="handleClick(item)"
                >查看详情</el-button
              >
            </div>
          </el-card>
        </el-col>
      </el-row>
    </div>
    <!--
      <div class="SubmenuPage1">-->

    <!--      <SubmenuPage2 class="SubmenuPage2"/>-->
  </div>
</template>

<script>
import SubmenuPage2 from "@/components/SubmenuPage2.vue";
import request from "@/utils/request";
export default {
  name: "NewsPage",
  components: { SubmenuPage2 },
  data() {
    return {
      activityList: [],
    };
  },
  created() {
    this.load();
    this.addViewers();
  },
  methods: {
    addViewers() {
      request.getRequest("/api/viewers").then((res) => {});
    },
    goToAbout() {
      this.$router.push({ name: "SubPage" });
    },
    goToDetail(id) {
      this.$router.push({ name: "ActivityDetail", query: { id: id } });
    },
    load() {
      request
        .getRequest("api/activity/find")
        .then((res) => {
          if (res && res.data) {
            this.activityList = res.data.data;
          }
        })
        .catch((error) => {
          this.$message({
            message: "加载失败",
            type: "error",
          });
        });
    },
    handleClick(item) {
      this.$router.push({ name: "ActivityDetail", query: { id: item.id } });
    },
  },
};
</script>
<style scoped>
.container {
  width: 1200px;
  margin: auto;
  height: 600px;
  box-shadow: #f6f8f7;
  margin-top: 100px;
}
.img-1 {
  width: 253px;
  height: 150px;
}
.image-in-grid {
  width: 400px;
  height: 120px;
}

.grid-content {
  width: 400px;
  border-radius: 4px;
  margin-top: 10px;
  height: 120px;
}
.container2 {
  margin-top: 20px;
}
.card-background {
  background-image: url("../assets/ab.jpg");
}
.bottom-button {
  margin-top: 20px;
}
</style>
