<template>
  <div class="swiper-content">
    <div class="carousel-container">
      <transition name="fade" mode="out-in">
        <div class="carousel-slide" :key="currentSlide">
          <el-image :src="slides[currentSlide]" class="img" />
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: "Swiper",
  data() {
    return {
      currentSlide: 0,
      slides: [
        require("../assets/f.jpg"),
        require("../assets/2.jpg"),
        require("../assets/6.jpg"),
        require("../assets/7.jpg"),
        require("../assets/home-swiper-1.jpg"),
        require("../assets/home-swiper-2.jpg"),
        require("../assets/home-swiper-3.jpg"),
        require("../assets/home-swiper-4.jpg"),
      ],
      intervalId: null, // 用于存储定时器的ID
    };
  },
  created() {
    this.startAutoPlay();
  },
  beforeDestroy() {
    this.stopAutoPlay();
  },
  methods: {
    nextSlide() {
      this.currentSlide = (this.currentSlide + 1) % this.slides.length;
    },
    prevSlide() {
      this.currentSlide =
        (this.currentSlide - 1 + this.slides.length) % this.slides.length;
    },
    startAutoPlay() {
      this.intervalId = setInterval(this.nextSlide, 3000); // 每3秒切换到下一张图片
    },
    stopAutoPlay() {
      if (this.intervalId) {
        clearInterval(this.intervalId);
        this.intervalId = null;
      }
    },
  },
};
</script>

<style scoped>
.swiper-content {
  width: 600px;
}
.carousel-container {
  width: 600px;
  overflow: hidden;
}
.carousel-slide {
  width: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.img {
  width: 600px;
  height: 550px;
  object-fit: cover;
  display: block;
  margin: auto;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
</style>
