<template>
    <div id="QR">

        <img
            v-bind:src="imageSrc"
            v-on:mouseover="imageSrc = hoveredImage"
            v-on:mouseout="imageSrc = defaultImage"
            alt="Hover Image Example"
        />
    </div>
</template>

<script>
export default {
    name:'QRCode',

    data() {
        return {
            defaultImage: require('../assets/k.png'), // 使用require来引入默认图片
            hoveredImage: require('../assets/j.jpg'), // 使用require来引入鼠标滑过时的图片
            imageSrc: require('../assets/k.png') // 初始图片设置为默认图片
        }
    }
}
</script>

<style>
#QR{
    margin-left: 1550px;

}
/* 可以在这里添加一些样式 */
#QR img {
    position: absolute;
    right: 0;
    width: 70px; /* 设置图片宽度 */
    height: 100px; /* 高度自适应 */
    transition: transform 0.3s ease; /* 添加一些过渡效果 */
}

#QR img:hover {
    transform: scale(1.05); /* 鼠标滑过时图片稍微放大 */
    width: 100px;
    height: 100px;
}

</style>
