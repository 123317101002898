<template>
  <div class="el-menu-1">
    <el-menu
      :default-active="activeIndex"
      class="el-menu-demo"
      mode="horizontal"
      :router="false"
      text-color="black"
      active-text-color="#000"
    >
      <el-menu-item index="/" @click="goHome">首页</el-menu-item>
      <el-submenu index="2">
        <template class="el-menu-item1" slot="title">国内高校服务站</template>
        <el-menu-item
          v-for="(item, index) in services2"
          :key="index"
          :index="'2-' + index"
          @click="handleItemClick(item)"
          >{{ item.title }}
        </el-menu-item>
      </el-submenu>
      <el-submenu index="3">
        <template slot="title" class="el-menu-item1">海外高校服务站</template>
        <el-menu-item
          v-for="(item, index) in services3"
          :key="index"
          :index="'3-' + index"
          @click="handleItemClick(item)"
          class="custom-menu-item"
          >{{ item.title }}
        </el-menu-item>
      </el-submenu>
      <el-submenu index="4">
        <template slot="title" class="el-menu-item1">海外社会服务站</template>
        <el-menu-item
          v-for="(item, index) in services4"
          :key="index"
          :index="'4-' + index"
          @click="handleItemClick(item)"
          class="custom-menu-item"
          >{{ item.title }}
        </el-menu-item>
      </el-submenu>
      <el-menu-item
        index="5"
        class="item-title1"
        @click="handleItemClick({ title: '成长助学', link: '#', type: 20 })"
      >
        成长助学
      </el-menu-item>
      <el-menu-item
        index="6"
        class="item-title1"
        @click="handleItemClick({ title: '义工风采', link: '#', type: 21 })"
      >
        义工风采
      </el-menu-item>
      <el-submenu index="7">
        <template slot="title" class="el-menu-item1">项目展示</template>
        <el-menu-item
          v-for="(item, index) in services7"
          :key="index"
          :index="'7-' + index"
          @click="handleItemClick(item)"
          class="custom-menu-item"
          >{{ item.title }}
        </el-menu-item>
      </el-submenu>
    </el-menu>
  </div>
</template>

<script>
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

export default {
  name: "SubmenuPage",
  components: { ElementUI },
  data() {
    return {
      activeIndex: "1",
      services2: [
        { title: "广西民族大学", link: "#", type: 1 },
        { title: "闽江师范高等专科学校", link: "#", type: 2 },
        { title: "福州市艺术学校", link: "#", type: 3 },
        { title: "中山大学", link: "#", type: 4 },
        { title: "香港中文大学", link: "#", type: 5 },
      ],
      services3: [
        { title: "印尼丹戎布拉大学孔院", link: "#", type: 6 },
        { title: "印尼阿拉扎大学", link: "#", type: 7 },
        { title: "泰国马哈沙拉坎大学", link: "#", type: 8 },
        { title: "老挝国立大学", link: "#", type: 9 },
      ],
      services4: [
        { title: "土耳其中资民企商会", link: "#", type: 10 },
        { title: "印民中资民企商会", link: "#", type: 11 },
        { title: "厄瓜多尔中华侨乡中心", link: "#", type: 12 },
      ],
      services7: [
        { title: "广西'桂姐姐'心理教育", link: "#", type: 13 },
        { title: "'茉莉姐姐'亲子教育工作室", link: "#", type: 14 },
        { title: "到海峡的另一边去", link: "#", type: 15 },
        { title: "顶梁柱母亲心理服务", link: "#", type: 16 },
        { title: "中学心理筛查", link: "#", type: 17 },
        { title: "'青苗计划'社区家长课堂", link: "#", type: 18 },
        { title: "守护星星畅享才艺", link: "#", type: 19 },
      ],
    };
  },
  methods: {
    handleItemClick(item) {
      // 当菜单项被点击时，执行这个方法
      // 如果需要根据 type 做路由跳转，可以在这里处理
      this.$router.push({
        path: "/ChooseNewsList",
        query: { type: item.type },
      });
    },

    goHome() {
      const currentRouteName = this.$route.name;
      if (currentRouteName === "NewHome") {
        this.$router.go();
      } else {
        this.$router.push({ name: "NewHome" });
      }
    },
  },
};
</script>

<style scoped>
.el-menu-1 {
  width: 1200px;
  margin: auto;
}

.el-menu-demo {
  background-color: #e60012;
  border-bottom: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.el-menu-demo .el-submenu__title {
  color: white !important;
  padding: 0 50px;
}

.el-menu-demo .el-menu-item,
.el-menu-demo .el-submenu__title {
  font-size: 16px;
  font-weight: 500;
  border-radius: 8px;
  margin: 0 10px;
  transition: background-color 0.3s, box-shadow 0.3s;
  height: 50px;
  line-height: 50px;
  color: white;
  /*  padding: 0 50px;*/
}
.el-submenu__title {
  color: white !important;
}
.el-menu-demo .el-menu-item:hover,
.el-menu-demo .el-submenu__title:hover {
  color: #e60012 !important;
  background-color: #e6e6e6 !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.el-menu-demo .el-menu-item.is-active {
  background-color: #ffe0f0 !important;
  color: #e60012;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.el-menu-demo .el-submenu .el-menu-item {
  flex: auto;
  min-width: 150px;
}

/* 子菜单的样式调整 */
.el-menu-demo .el-submenu__title {
  /* padding: 0 50px;*/
}

/* 子菜单展开时的样式 */
.el-menu-demo .el-menu--popup {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.el-menu-demo .el-menu--popup .el-menu-item {
  font-size: 14px;
  border-radius: 4px;
}

.el-menu-demo .el-menu--popup .el-menu-item:hover {
  background-color: #f5f5f5 !important;
}

/* 增加子菜单项的内间距 */
.el-menu-demo .el-menu--popup .el-menu-item {
  font-size: 14px;
  border-radius: 4px;
}

/* 增加子菜单标题的内间距 */
.el-menu-demo .el-submenu__title {
  /* 其他样式保持不变 */
}

/* 如果需要，也可以调整主菜单项的内间距 */
.el-menu-demo .el-menu-item,
.el-menu-demo .el-submenu__title {
  /* 其他样式保持不变 */
}

.el-submenu:not(:last-child) {
  margin-left: 60px; /* 根据需要调整间距 */
}
</style>
