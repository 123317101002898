<template>
  <div class="header-container">
    <div class="header">
      <div class="header-content">
        <div class="header-logo">
          <div class="logo">
            <img class="img-1" src="../assets/9.jpg" />
          </div>
        </div>
        <div class="right-box">
          <div class="search-and-phone">
            <input type="text" placeholder="请输入搜索内容" />
          </div>
          <div class="login-register">
            <h3 v-if="!$store.state.isLogin">
              <a @click="goToLogin">登录</a> <span class="span-1">|</span>
              <a @click="goToRegister">注册</a>
            </h3>
            <div v-else class="user-info" @click="goPersonal">
              <img :src="$store.state.userInfo.avatar" class="user-avatar" />
              <div>
                {{ $store.state.userInfo.username }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <SubmenuPage />
  </div>
</template>

<script>
import SubmenuPage from "@/components/SubmenuPage.vue";
export default {
  name: "TopNavbar",
  components: { SubmenuPage },
  data() {
    return {};
  },
  methods: {
    goToLogin() {
      // 使用编程式导航
      this.$router.push({ name: "LoginPage" }); // 使用路由名称
    },
    goToRegister() {
      // 使用编程式导航
      this.$router.push({ name: "RegisterPage" }); // 使用路由名称
    },
    goPersonal() {
      this.$router.push({ name: "PersonalActivity" });
    },
  },
};
</script>

<style scoped>
.right-box {
  width: 100%;
  margin-left: 100px;
  display: flex;
  justify-content: space-between;
  justify-items: center;
  align-items: center;
}

.user-avatar {
  width: 50px;
  height: 50px;
  border-radius: 100px;
  align-items: flex-end;
  right: 0;
}
.user-info {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.user-info:hover {
  color: #00bc70;
}
.header {
  background-image: url("../assets/58229ff56141b68bb04e01563537f8c8.jpg");
  width: 100%;
  display: flex;
  align-items: center;
  background-color: #fff; /* 背景色 */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* 阴影效果 */
  z-index: 1000; /* 确保它位于页面顶部 */
  height: 110px;
  /*margin-top: 10px;*/
}
.header-container {
  width: 100%;
  background-color: #e60012;
}
.header-content {
  width: 1200px;
  margin: auto; /* 水平居中 */
  display: flex;
}

/*.logo {
    !* 你可以根据需要调整logo的样式 *!
    display: block; !* 防止图片下方有空隙 *!
}*/

.img-1 {
  width: 420px;
  height: 88px; /* 保持图片比例 */
  /* 移除margin-top和margin-left，因为它们现在可能不再需要 */
}

.search-and-phone {
  margin-top: 25px;
}

.search-and-phone input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  /*  margin-top: 18px;*/
  width: 500px;
  height: 40px;
}

.search-and-phone span {
  font-weight: bold;
  margin-top: 18px;
  margin-left: 8px;
}
.login-register {
  margin-top: 20px;
  margin-left: 50px;
  cursor: pointer;
}
.login-register a {
  color: whitesmoke;
}
.login-register a:hover {
  color: brown;
}
.span-1 {
  color: grey;
}
h3 {
  width: 200px;
}
</style>
