<template>

    <div class="container">

        <div class="left">
            <div class="left-top">
                <h1>新闻咨询</h1>
                <h2>NEWS INFORMATION</h2>
              <el-button class="left-top-bottom" @click="goToAbout"
              >查看更多</el-button
              >
            </div>
<!--            <div class="left-bottom"></div>-->
<!--            <div class="left-bottom2"></div>-->
        </div>
        <div class="container2">
                    <div v-for="(item,index) in newList" :key="item.id"  class="link-item" v-if="index < 6"  @click="handleClick(item)">
                        <div style="width: 100%;">
                          <div class="title">
                            <div class="title-index">{{index + 1 }}</div>
                            {{ item.title }}
                          </div>
                          <div class="item-time">{{item.createTime | dateFormat}}</div>
                        </div>
                    </div>
        </div>
    </div>
</template>
<script>
import request from "@/utils/request";

export default {
    name:"LinkedPage",
    data(){
        return{
            newList:[],
            HomeNewList:[],
        };
    },

    created() {
        this.load();
    },
        methods: {
            goToAbout() {
                this.$router.push({ name: "SubPage" });
            },
            goToDetail(id) {
                this.$router.push({ name: "ActivityDetail", params: { id } });
            },
            load() {
                request.getRequest("/api/essay/HomeNewList").then((res) => {
                    if (res && res.data) {
                        this.newList = res.data.data;
                        console.log(this.newList)
                    }
                })
                    .catch((error) => {
                        this.$message({
                            message: "加载失败",
                            type: "error",
                        });
                    });
            },

            handleClick(item) {
                this.$router.push({ name: "NewsDetail", params: { id: item.id } });
            },
        },
    };


</script>
<style scoped>
.container{
    width: 580px;
    height: 550px;
    box-shadow: #f6f8f7;
  margin-left: 20px;
}
.left{
  height: 120px;
  width: 100%;
}
.container2{
    width: 600px;
height: 430px;
  margin-top: 10px;
}
.left-top-bottom {
    width: 100px;
    height: 40px;
    border-radius: 10px;
    cursor: pointer;
    transition: margin 0.5s; /* 平滑过渡效果 */
}
.title{
  display: flex;
}
.title-index{
  width: 20px;
  height: 20px;
  background-color: #666666;
  border-radius: 50%;
  flex-shrink:0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}
.link-item{
  display: flex;
  justify-items: center;
  align-items: center;
  height: 70px;
  border-bottom: 1px solid beige;
  cursor: pointer;
}
.link-item:hover{
  color: red;
}

.item-time{
  width: 100%;
  text-align: right;
}
</style>