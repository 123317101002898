// 引入 Vue 和 VueRouter
import Vue from "vue";
import VueRouter from 'vue-router';
Vue.use(VueRouter)


// 引入组件（注意路径可能需要调整以匹配您的项目结构）
// 假设这些路径是正确的，或者您已经根据您的项目结构调整了它们
const Home = () => import('@/views/home.vue');
const SubPage = () => import('@/components/SecondPage.vue');
const NewHome = () => import('@/components/NewHome.vue');
const LoginPage = () => import('@/components/LoginPage.vue');
const RegisterPage = () => import('@/components/RegisterPage.vue');
const PersonalActivity = () => import('@/components/PersonalActivity.vue');
const NewsDetail = () => import('@/components/NewsDetail.vue');
const ActivityDetail = () => import('@/components/ActivityDetail.vue');
const ChooseNewsList = () => import('@/components/ChooseNewsList.vue');
const Layout = () => import('@/components/Layout.vue');

// 定义路由
// 在 Vue 2 中，你不需要显式声明为 RouteRecordRaw 数组，但结构是类似的
const routes = [
  { path: '/', component: Home },
  { path: '/news', component: SubPage, name: 'SubPage' },
  { path: '/login', component: LoginPage, name: 'LoginPage' },
  { path: '/register', component: RegisterPage, name: 'RegisterPage' },
  { path: '/NewHome', component: NewHome, name: 'NewHome' },
  { path: '/ActivityDetail', component: ActivityDetail, name: 'ActivityDetail', props: true },
  { path: '/PersonalActivity', component: PersonalActivity, name: 'PersonalActivity' },
  { path: '/NewsDetail/:id', component: NewsDetail, name: 'NewsDetail', props: true },
  { path: '/ChooseNewsList', component: ChooseNewsList, name: 'ChooseNewsList' },
  { path: '/Layout', component: Layout, name: 'Layout' },

];

// 创建并导出路由实例
// 注意：在 Vue 2 中，mode 直接作为字符串传递
const router = new VueRouter({
  routes
})

/** 解决跳转重复路由报错问题 */
const routerPush = router.push;
router.push = path => {
  // 判断下当前路由是否就是要跳转的路由
  if (router.currentRoute.fullPath.includes(path)) return;
  return routerPush.call(router, path);
}


export default router
// 确保在你的 Vue 实例中使用这个 router
// 例如，在你的 main.js 或类似的入口文件中：
// new Vue({
//   router,
//   render: h => h(App),
// }).$mount('#app');